.contents {
    position: relative;
    display: grid;
    place-items: center;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background-color: #fff;
    overflow: hidden;
  }
  .loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* 透明な背景色やオーバーレイを指定  background: rgba(255, 255, 255,); /* オーバーレイを指定しない */ 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* 他の要素より前面に表示 */
  }
  
  
  .loading-area {
    display: flex;
    justify-content: center;
    gap: 3px;
    font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', '游ゴシック Medium', YuGothic, YuGothicM, 'Hiragino Kaku Gothic ProN', メイリオ, Meiryo, sans-serif;
  }
  
  .loading-area span {
    display: inline-block;
    background-color: rgb(155, 0, 63);
    background-image: linear-gradient(#FF0000 0 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-size: 100% 0%;
    background-position: center bottom;
    background-repeat: no-repeat;
    font-size: 3rem;
    letter-spacing: 20px; /* 文字と文字の間隔を調整 */
    font-weight: bold;
    animation: water-anime 2s infinite linear;
    text-transform: uppercase;
    @media screen and (max-width: 768px){
      font-size: 2rem;
      letter-spacing: 5px;
    }
  }
  
  .loading-area span:nth-child(1) {
    animation-delay: 0.1s;
  }
  
  .loading-area span:nth-child(2) {
    animation-delay: 0.14s;
  }
  
  .loading-area span:nth-child(3) {
    animation-delay: 0.18s;
  }
  
  .loading-area span:nth-child(4) {
    animation-delay: 0.22s;
  }
  
  .loading-area span:nth-child(5) {
    animation-delay: 0.26s;
  }
  
  .loading-area span:nth-child(6) {
    animation-delay: 0.3s;
  }
  
  .loading-area span:nth-child(7) {
    animation-delay: 0.34s;
  }
  
  .loading-area span:nth-child(8) {
    animation-delay: 0.38s;
  }
  
  .loading-area span:nth-child(9) {
    animation-delay: 0.4s;
  }
  
  .loading-area span:nth-child(10) {
    animation-delay: 0.44s;
  }
  
  @keyframes water-anime {
  
    70%,
    100% {
        background-size: 100% 100%;
    }
  }